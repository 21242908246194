

import PSILogo from './assets/psilogo1.png'
import OTELogo from './assets/Oval_DINO_AND_BIKE_LOGO_fullcolor.png'
import PNWLogo from './assets/PNW_Components_Logo.png'
import PitViperLogo from './assets/PitViper.png'
import WolftoothLogo from './assets/logo_image_color_wolftooth.png'
import HandupLogo from './assets/HandupMainLogo.png'
import YellowCatLogo from './assets/yellowcatlogo.jpg'
import CushCoreLogo from './assets/CushCore_Wordmark_Green.png'
import BoaLogo from './assets/boa-logo.jpg'
import SweetProLogo from './assets/sweet-pro-logo.jpg'
import RockyLogo from './assets/rockymounts.png'
import WildRyeLogo from './assets/WildRyeLogo.png'
import MontuckyLogo from './assets/MontuckyAqua.png'
import StioLogo from './assets/stio-logo-2018.jpg'
import IbexLogo from './assets/IbexLogo.png'
import HotLogo from './assets/hot_tomato.png'
import OvejaLogo from './assets/oveja-negra-logo.png'
import SkidaLogo from './assets/skida-logo.png'
import DakineLogo from './assets/DAKINE_Rail.png'

export default function SponsorNascar() {

    const IndustrySponsors = [
        {
            name: 'Over The Edge Sports',
            description:
                'Over the Edge Sports was born in Fruita, Colorado, USA and has been serving the western slope of Colorado with since 1995.',
            logo: OTELogo,
            url: 'https://www.otefruita.com'
        },
        {
            name: 'Party Shirt International',
            description:
                'Party Shirt International is a company specializing in designing and selling unique, stylish party shirts for all occasions.',
            logo: PSILogo,
            url: 'https://partyshirtinternational.com/'
        },
        {
            name: 'The Hot Tomato',
            description:
                `The Hot Tomato is a lively, funky pizza restaurant with a biked-themed backdrop for elaborate homemade pies & microbrews.`,
            logo: HotLogo,
            url: 'https://www.hottomatopizza.com/'
        },
        {
            name: 'BOA',
            description:
                'The BOA® Fit System delivers micro-adjustable precision fit, engineered to perform in the toughest conditions.',
            logo: BoaLogo,
            url: 'https://www.boafit.com/en-us'
        },
        {
            name: 'Montucky Cold Snacks',
            description:
                `The world's most refreshing beer company that gives back to the local community while celebrating the outdoors, and having fun.`,
            logo: MontuckyLogo,
            url: 'https://montuckycoldsnacks.com'
        },
        // {
        //     name: 'Sweet Protection',
        //     description:
        //         'Sweet Protection creates superior protection inspiring people to push their boundaries.',
        //     logo: SweetProLogo,
        //     url: 'https://www.sweetprotection.com/us/en/'
        // },
        {
            name: 'Ibex Apparel',
            description:
                'Ibex wool is New Zealand and Australian-grown Merino – a natural high-performance fiber',
            logo: IbexLogo,
            url: 'https://ibex.com/'
        },
        {
            name: 'Wolftooth Components',
            description: 'Wolf Tooth was founded to design and manufacture precision cycling products that emphasize function, performance, and reliability.',
            logo: WolftoothLogo,
            url: 'https://www.wolftoothcomponents.com/'
        },
        {
            name: 'RockyMounts',
            description: 'RockyMounts was created with an ideology - when you absolutely, positively can’t ride it there; we’ve got you covered.',
            logo: RockyLogo,
            url: 'https://rockymounts.com/'
        },
        {
            name: 'Handup Gloves',
            description:
                `Handup Gloves have been the best mountain bike gloves for grabbin' bars and beers since 2014.`,
            logo: HandupLogo,
            url: 'https://handupgloves.com'
        },
        {
            name: 'PNW Components',
            description:
                'PNW Components believes that reliability and affordability can go hand in hand, and bikes are primarily made for the sheer fun they add to our lives.',
            logo: PNWLogo,
            url: 'https://www.pnwcomponents.com'
        },
        {
            name: 'Wild Rye',
            description:
                `Wild Rye crafts design-driven ski and mountain bike apparel for the modern outdoors woman.`,
            logo: WildRyeLogo,
            url: 'https://wild-rye.com/?gclid=CjwKCAjw-7OlBhB8EiwAnoOEk-z_aPQjQWxVi_v1qzjEuFkLwz8aImgvqCZYW4upBTmALJ2gdAq_XhoCxvUQAvD_BwE'
        },
        {
            name: 'Yellow Cat Industries',
        description:
                `Yellow Cat is a small run manufacturing company. Designing and manufacturing textile goods used in everyday and outdoor life. `,
            logo: YellowCatLogo,
            url: 'https://www.yellowcatindustries.com/'
        },
        {
            name: 'Cush Core',
            description:
                'CushCore tire inserts give you total confidence. Their patented bicycle tire insert absorbs impacts, prevents flats, and protects rims.',
            logo: CushCoreLogo,
            url: 'https://cushcore.com'
        },
        {
            name: 'Stio',
            description:
                `Stio is apparel we are confident you will live in, in both the epic and everyday moments of your outdoor life.`,
            logo: StioLogo,
            url: 'https://www.stio.com'
        },
        {
            name: 'Pit Viper',
            description:
                'Pit Viper is a company that designs and sells stylish, durable and high-performance sunglasses and accessories for outdoor enthusiasts and adventurers.',
            logo: PitViperLogo,
            url: 'https://pitviper.com/'
        },
        {
            name: 'Oveja Negra',
            description:
                `Oveja Negra designs, manufactures, and sells top-shelf bikepacking bags out of our fun and bustling factory in Salida, CO`,
            logo: OvejaLogo,
            url: 'https://www.ovejanegrabikepacking.com'
        },
        {
            name: 'Skida',
            description:
                `Made-in-Vermont, ski-inspired headwear and winter accessories.`,
            logo: SkidaLogo,
            url: 'https://skida.com/'
        },
        {
            name: 'Dakine',
            description:
                `Dakine builds backpacks, travel bags, accessories, outerwear and clothing for people who love to surf, snowboard, skateboard, mountain bike, ski, windsurf, kiteboard and travel.`,
            logo: DakineLogo,
            url: 'https://www.dakine.com/'
        },
    ]

    // const CorporateSponsors = [
    //     {
    //         name: 'Nelson Dairy Consultants',
    //         description:
    //             'Trusted, hands-on dairy management and nutrition experts.',
    //         logo: NDCLogo,
    //         url: 'https://nelsondairyconsultants.com'
    //     },
    //     {
    //         name: 'Trailhead Wealth Management',
    //         description:
    //             'Helping our clients achieve their ideal quality of life.',
    //         logo: TrailheadLogo,
    //         url: 'https://trailheadwm.com/'
    //     }
    // ]

    return (
        <div className="bg-mustard overflow-hidden">
            <div className="py-24">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto max-w-2xl lg:text-center">
                        <p className="mt-4 text-5xl font-bold tracking-tight text-dark sm:text-5xl">
                            Industry Partners
                        </p>
                        <p className="mt-4 text-xl leading-8 text-gray-800">
                            The Hotdogger would not be possible without the support of these amazing brands, hover over them to learn more!
                        </p>
                    </div>
                    <div className="mx-auto mt-6 max-w-2xl lg:max-w-6xl text-center">
                        <dl className="w-full flex flex-wrap gap-4 md:gap-8 justify-center">
                            {IndustrySponsors.map((sponsor) => (
                                <a href={sponsor.url} target="_blank" className="group relative block h-64 w-full mx-8 sm:mx-0 sm:w-64">
                                    <span className="absolute inset-0 border-2 border-dashed border-black"></span>
                                    <div className=" relative flex h-full transform items-end border-2 border-black bg-white transition-transform group-hover:-translate-x-2 group-hover:-translate-y-2">
                                        <div className="px-8 pb-8 transition-opacity group-hover:absolute group-hover:opacity-0 w-full">
                                            <div className="w-full h-full">
                                                <img
                                                    alt="Sponsor Logo"
                                                    src={sponsor.logo}
                                                    className="object-contain h-44 text-center mx-auto"
                                                />
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                            </svg>
                                        </div>
                                        <div className="absolute mb-10 p-4 md:mb-0 md:p-6 opacity-0 transition-opacity group-hover:relative group-hover:opacity-100" >
                                            <h3 className="md:mt-4 text-2xl md:text-xl font-medium">{sponsor.name}</h3>
                                            <p className="mt-4 md:text-sm">
                                                {sponsor.description}
                                            </p>
                                            <p className="mt-4 font-bold">Click to learn more</p>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </dl>
                    </div>
                    {/* <div className="mx-auto max-w-2xl lg:text-center mt-12">
                        <p className="text-5xl font-bold tracking-tight text-dark sm:text-5xl">
                            Corporate Sponsors
                        </p>
                        <p className="mt-4 text-xl leading-8 text-gray-800">
                            These incredibly generous companies are supporting The Hotdogger, hover over them to learn more!
                        </p>
                    </div>
                    <div className="mx-auto mt-6 max-w-2xl lg:max-w-5xl text-center">
                        <dl className="w-full flex flex-wrap gap-6 md:gap-8 justify-center">
                            {CorporateSponsors.map((sponsor) => (
                                <a href={sponsor.url} target="_blank" className="group relative block h-80 w-full mx-8 md:mx-0 md:w-80">
                                    <span className="absolute inset-0 border-2 border-dashed border-black"></span>
                                    <div className="relative flex h-full transform items-end border-2 border-black bg-white transition-transform group-hover:-translate-x-2 group-hover:-translate-y-2">
                                        <div className="px-8 pb-8 transition-opacity group-hover:absolute group-hover:opacity-0 w-full">
                                            <div className="w-full h-full">
                                                <img
                                                    alt="Sponsor Logo"
                                                    src={sponsor.logo}
                                                    className="object-contain h-56 w-112 text-center mx-auto"
                                                />
                                            </div>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                            </svg>
                                        </div>
                                        <div className="absolute p-8 opacity-0 transition-opacity group-hover:relative group-hover:opacity-100" >
                                            <h3 className="mt-4 text-2xl font-medium">{sponsor.name}</h3>
                                            <p className="mt-4">
                                                {sponsor.description}
                                            </p>
                                            <p className="mt-8 font-bold">Click to learn more</p>
                                        </div>
                                    </div>
                                </a>
                            ))}
                        </dl>
                    </div> */}
                </div>
            </div>
            <svg  style={{ transform: 'translateY(1px)', maxHeight: 100 }} className='w-screen' preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#E4788E" fill-opacity="1" d="M0,64L21.8,64C43.6,64,87,64,131,106.7C174.5,149,218,235,262,245.3C305.5,256,349,192,393,160C436.4,128,480,128,524,112C567.3,96,611,64,655,69.3C698.2,75,742,117,785,122.7C829.1,128,873,96,916,106.7C960,117,1004,171,1047,202.7C1090.9,235,1135,245,1178,234.7C1221.8,224,1265,192,1309,186.7C1352.7,181,1396,203,1418,213.3L1440,224L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"></path>
            </svg>
        </div>
    )
}
